//event listener: DOM ready
function addLoadEvent(func) {
  var oldonload = window.onload;
  if (typeof window.onload != "function") {
    window.onload = func;
  } else {
    window.onload = function() {
      if (oldonload) {
        oldonload();
      }
      func();
    };
  }
}

//call plugin function after DOM ready
var supportsPromise =
  typeof Promise !== "undefined" &&
  Object.prototype.toString.call(Promise.resolve()) === "[object Promise]";

//call plugin function after DOM ready
addLoadEvent(function() {
  if (false === supportsPromise) {
    outdatedBrowser({
      bgColor: "#f25648",
      color: "#ffffff",
      lowerThan: true
    });
  }
});

$(".last").click(function() {
  $("#outdated").css({ display: "none", opacity: "0" });
});
