// Login Modal Form Validation
$("#login-form")
  .parsley({
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden",
    errorClass: "has-danger",
    successClass: "has-success",
    classHandler: function(ParsleyField) {
      return ParsleyField.$element.closest(".form-group");
    },
    errorsContainer: function(ParsleyField) {
      return ParsleyField.$element.closest(".form-group");
    },
    errorsWrapper: '<div class="invalid-feedback"></div>',
    errorTemplate: "<span></span>"
  })
  .on("form:validate", function() {
    $("#login-form").addClass("was-validated");
  });
