/*** Get Parameter By Name ***/
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
} /* Get Parameter By Name */

var loginFailedMessage = getParameterByName("loginFailedMessage");
var message = getParameterByName("message");
var accountToolsNotes = getParameterByName("reqNotes");
var login = getParameterByName("login");
var notificationMessage = getParameterByName("notificationMessage");

/*** Form Confirmation ***/
if (message != null) {
  // append message to reveal modal
  $("#form-confirmation .message").append(message); // console.log(message);
  $("#form-confirmation").modal("show");
} // if message is NOT null

/*** Login Form Confirmation ***/
if (loginFailedMessage != null) {
  // append message to reveal modal
  $("#login-failed .message").append(loginFailedMessage);
  $("#login-failed").modal("show");
} // if loginFailedMessage is NOT null

/*** Show login modal after failed modal is dismissed ***/
$("#login-failed").on("hidden.bs.modal", function(e) {
  $("#loginModal").modal("show");
});

/*** Account Tools Form Confirmation ***/
if (accountToolsNotes != null) {
  // append message to reveal modal
  $("#form-confirmation .message").html(
    "<p>Documents have been requested!</p>"
  );
  $("#form-confirmation").modal("show");
} // if reqNotes is NOT null

/*** Login - Auto Open Modal/Reveal ***/
if (login != null) {
  $("#loginModal").modal("show");
} // if login is NOT null

/*** FT - Notification Message ***/
if (notificationMessage != null) {
  $(".ft-notification-body").append(notificationMessage);
  $(".ft-notification").removeClass("d-none");

  setTimeout(function() {
    $(".ft-notification").hide(function() {
      $(".ft-notification-body").html("");
    });
  }, 5000);
} // if login is NOT null
